<template>
  <main class="main">
    <div class="div">
      <span class="lxfs">联系方式</span>
      <span class="zjf">联系人：{{ initdata2.contactName }}</span>
      <span class="tel">Tel&nbsp & &nbsp WeChat：{{ initdata2.phone }}</span>
      <span class="add">Add：{{ initdata2.address }}</span>
      <div class="box1"></div>
      <div class="box2"></div>
      <span class="yqlj">友情链接</span>
      <div class="yqlj1">
        <a :href="item.linkUrl" target="_blank" v-for="(item, index) in yqlj1" :key="index" class="yqlj2">{{
          item.linkName }}</a>
      </div>
      <!-- <a href="http://www.zgjssw.gov.cn/" class="a4" target="_blank" style="color:#fff"
        >中共江苏省委宣传部</a
      >
      <a href="http://wlt.jiangsu.gov.cn/" class="a5" target="_blank" style="color:#fff"
        >江苏省文化和旅游厅</a
      >
      <a href="http://www.zgjssw.gov.cn/" class="a1" target="_blank" style="color:#fff"
        >中共江苏省委员会</a
      >
      <a href="http://www.jiangsu.gov.cn/" class="a2" target="_blank" style="color:#fff"
        >江苏省人民政府</a
      >
      <a
        href="http://www.jscimg.com.cn/cms/index.php" style="color:#fff"
        class="a6"
        target="_blank"
        >江苏省文化投资管理集团</a
      > -->
      <span class="fwpt">大运河文旅企业服务平台</span>
      <a href="https://beian.miit.gov.cn/" target="_blank" class="one">苏ICP 备20029599号-2</a>
    </div>
  </main>
</template>

<script>
import { getLxfs, getYqljlb } from "@/api/wslapi/";
export default {
  data() {
    return {
      initdata2: [],
      yqlj1: [],
    };
  },
  methods: {
    initdata() {
      getLxfs().then((res) => {
        console.log("res===", res)
        this.initdata2 = res.data.data;
        // console.log(res.data.data);
      });
    },
    yqljlb() {
      getYqljlb().then((res) => {
        this.yqlj1 = res.data.data;
      });
    },
  },
  created() {
    this.initdata();
    this.yqljlb();
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0 !important;
  padding: 0 !important;
}

.main {
  height: 350px;
  width: 100%;
  background-color: #1b1c20 !important;

  .div {
    height: 350px;
    width: 100%;
    position: relative;
    background-color: #1b1c20 !important;
  }

  .lxfs {
    min-width: 84px;
    height: 28px;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    line-height: 28px;
    position: absolute;
    top: 80px;
    left: 320px;
  }

  .zjf {
    min-width: 84px;
    height: 28px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    line-height: 28px;
    position: absolute;
    top: 120px;
    left: 320px;
    text-align: left;
  }

  .tel {
    min-width: 84px;
    height: 28px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    line-height: 28px;
    position: absolute;
    top: 160px;
    left: 320px;
    text-align: left;
  }

  .add {
    height: 28px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    line-height: 28px;
    position: absolute;
    top: 200px;
    left: 320px;
    text-align: left;
  }

  .box1 {
    min-width: 2px;
    height: 100px;
    background: gray;
    position: absolute;
    top: 128px;
    left: 753px;
  }

  .box2 {
    min-width: 100%;
    height: 2px;
    background: gray;
    position: absolute;
    top: 260px;
    left: 0;
  }

  .yqlj {
    min-width: 84px;
    height: 28px;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    line-height: 28px;
    position: absolute;
    top: 80px;
    left: 915px;
  }

  .a1 {
    min-width: 194px;
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: #ffff;
    line-height: 22px;
    letter-spacing: 1px;
    position: absolute;
    top: 160px;
    left: 915px;
    text-align: left;
  }

  .a2 {
    min-width: 194px;
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: #ffff;
    line-height: 22px;
    letter-spacing: 1px;
    position: absolute;
    top: 160px;
    left: 1200px;
    text-align: left;
  }

  .a3 {
    min-width: 194px;
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: #ffff;
    line-height: 22px;
    letter-spacing: 1px;
    position: absolute;
    top: 160px;
    left: 1500px;
    text-align: left;
  }

  .a4 {
    min-width: 194px;
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: #ffff;
    line-height: 22px;
    letter-spacing: 1px;
    position: absolute;
    top: 200px;
    left: 915px;
    text-align: left;
  }

  .a5 {
    min-width: 194px;
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: #ffff !important;
    line-height: 22px;
    letter-spacing: 1px;
    position: absolute;
    top: 200px;
    left: 1200px;
    text-align: left;
  }

  .a6 {
    min-width: 194px;
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: #ffff;
    line-height: 22px;
    letter-spacing: 1px;
    position: absolute;
    top: 200px;
    left: 1500px;
    text-align: left;
  }

  .fwpt {
    min-width: 184px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 22px;
    position: absolute;
    top: 280px;
    left: 800px;
  }

  .one {
    min-width: 184px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 22px;
    position: absolute;
    top: 310px;
    left: 800px;
  }

  .yqlj1 {
    min-width: 500px;
    height: 90px;
    position: absolute;
    top: 108px;
    left: 805px;
    display: flex;
    // flex-direction:row;
    // justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;

    .yqlj2 {
      // display: block;
      width: 200px;
      color: #ffff;
      margin-left: 110px !important;
      margin-top: 16px !important;
      font-size: 17px;
      text-align: left;
    }
  }
}
</style>
