import axios from "axios";
import md5 from "js-md5";
// 2. 配置-统一请求接口网址
const require = axios.create({
  // baseURL: "http://118.195.148.216:8081",
  baseURL: "https://grandcanal-pro-api.d-y-h.cn/",
});
// 请求拦截器
require.interceptors.request.use((config) => {
  let getGuid = (len, radix) => {
    if (len == null || len == 0 || len == "" || len == undefined) {
      len = 32;
    }
    if (radix == null || radix == 0 || radix == "" || radix == undefined) {
      radix = 16;
    }
    let CHARS = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split(
      ""
    );
    let chars = CHARS,
      uuid = [],
      i;
    radix = radix || chars.length;
    if (len) {
      for (i = 0; i < len; i++) {
        uuid[i] = chars[0 | (Math.random() * radix)];
      }
    } else {
      let r;
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = "‐";
      uuid[14] = "4";
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | (Math.random() * 16);
          uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
        }
      }
    }
    return uuid.join("");
  };
  //3
  let getNowTimestamp = () => {
    let timestamp = new Date()
      .getTime()
      .toString()
      .substr(0, 10);
    return timestamp;
  };
  let times = getNowTimestamp();
  let uuids = getGuid();
  config.headers["Ql-Auth-Timestamp"] = times;
  config.headers["Ql-Auth-Nonce"] = uuids;
  config.headers["Ql-Auth-Sign"] = md5(
    md5(uuids + times) + "abcdefg!@#12344321!777"
  );
  return config;
});

export default require;
