import request from '..//index.js'
// 获取导航配置接口

export const getLxfss = params => {
    return request({
        method: 'GET',
        url: '/api/nsc/weroad/getCookie?authCode=ZWYwYTBiYjM4N2VjNGMyMzk4YTA0Yjlj',
        params
    })
}
// 底部联系方式接口
export const getLxfs = params => {
    return request({
        method: 'GET',
        url: '/api/nsc/front/ndContactConf/getContactConf',
        params
    })
}
// 首页合作伙伴接口
export const getYqlj = params => {
    return request({
        method: 'GET',
        url: '/api/nsc/front/ndPartner/list',
        params
    })
}
// 首页视频连接接口
export const getXcsp = params => {
    return request({
        method: 'GET',
        url: '/api/nsc/front/ndAdVideoConf/AdVideoConf',
        params
    })
}

// 友情链接
export const getYqljlb = params => {
    return request({
        method: 'GET',
        url: '/api/nsc/front/ndFriendlyLink/list',
        params
    })
}
// 导航接口请求

export const getDh = params => {
    return request({
        method: 'GET',
        url: '/api/nsc/front/ndFrontMenu/list',
        params
    })
}

// tab页接口
export const getTab = params => {
    return request({
        method: 'GET',
        url: 'api/nsc/front/ndFrontMenuTab/getInfoById/d6d06c7a-dcce-45f5-b339-124fd06dea41',
        params
    })
}

// 平台介绍
export const getPtsj = params => {
    return request({
        method: 'GET',
        url: '/api/nsc/front/ndPlatformConf/getPlatformConf',
        params
    })
}
// 轮播图接口
export const getLbt = params => {
    return request({
        method: 'GET',
        url: '/api/nsc/front/ndFrontMenuBanner/getInfoById/d6d06c7a-dcce-45f5-b339-124fd06dea41',
        params
    })
}

// 智慧酒店详情页接口数据
export const getZhjdxqy = params => {
    return request({
        method: 'GET',
        url: '/api/nsc/front/ndFrontPage/getInfoById/b6675309-7be7-4836-a922-4dc681289aac',
        params
    })
}
// 智慧酒店详情页
export const getZhjdxqyy = params => {
    return request({
        method: 'GET',
        url: 'https://test-dyh-ywzt-1302104628.cos.ap-nanjing.myqcloud.com/mediadayunhetest/d134543d2b444edaa7c94a86bd721614',
        params
    })
}
// 一站式服务接口
export const getYzsfw = params => {
    return request({
        method: 'GET',
        url: '/api/nsc/front/ndFrontPage/getInfoById/d6d06c7a-dcce-45f5-b339-124fd06dea41',
        params
    })
}
// 一站式服务详情页
export const getYzsfwxqy = params => {
    return request({
        method: 'GET',
        url: 'https://test-dyh-ywzt-1302104628.cos.ap-nanjing.myqcloud.com/mediadayunhetest/f066379bbc4744319da8459ec0e5ce78',
        params
    })
}
// 交通指南接口
export const getJtzn = params => {
    return request({
        method: 'GET',
        url: '/api/nsc/front/ndFrontPage/getInfoById/0916e6a1-ce95-4437-b35a-55382c4372f6',
        params
    })
}
// 交通指南详情页
export const getJtznxqy = params => {
    return request({
        method: 'GET',
        url: 'https://test-dyh-ywzt-1302104628.cos.ap-nanjing.myqcloud.com/mediadayunhetest/1e9ec74bb94d4937bbfc37220e050d33',
        params
    })
}
// 服务诱导接口
export const getFwyd = params => {
    return request({
        method: 'GET',
        url: '/api/nsc/front/ndFrontPage/getInfoById/07958b58-4c03-4191-a826-d2b3fe03956e',
        params
    })
}
// 服务诱导详情页
export const getFwydxqy = params => {
    return request({
        method: 'GET',
        url: 'https://test-dyh-ywzt-1302104628.cos.ap-nanjing.myqcloud.com/mediadayunhetest/6302cee7e6354806b75abc2bc964a8f9',
        params
    })
}

// 游记自动生成接口
export const getYjzdsc = params => {
    return request({
        method: 'GET',
        url: '/api/nsc/front/ndFrontPage/getInfoById/b6de503a-0d39-4689-af10-498beb36d23e',
        params
    })
}

// 游记自动生成详情页
export const getYjzdscxqy = params => {
    return request({
        method: 'GET',
        url: 'https://test-dyh-ywzt-1302104628.cos.ap-nanjing.myqcloud.com/mediadayunhetest/313d4cabc456489f81a97fd7e4b7c7ea',
        params
    })
}

// 导游导览接口
export const getDydl = params => {
    return request({
        method: 'GET',
        url: '/api/nsc/front/ndFrontPage/getInfoById/3c91369d-d937-4587-bfed-1b4d2777baca',
        params
    })
}

// 导游导览详情页
export const getDydlxqy = params => {
    return request({
        method: 'GET',
        url: 'https://test-dyh-ywzt-1302104628.cos.ap-nanjing.myqcloud.com/mediadayunhetest/5e0a522385994290a4bc8a64bccc1d04',
        params
    })
}

//大数据分析决策接口
export const getSjfx = params => {
    return request({
        method: 'GET',
        url: '/api/nsc/front/ndFrontPage/getInfoById/1a831a50-8ceb-4808-aac4-eeb5ffc00e22',
        params
    })
}

// 大数据分析决策详情页
export const getSjfxxqy = params => {
    return request({
        method: 'GET',
        url: 'https://test-dyh-ywzt-1302104628.cos.ap-nanjing.myqcloud.com/mediadayunhetest/3e4611becb9e426193cc1f1af5b277f7',
        params
    })
}

//智慧工具箱接口请求
export const getTygjx = params => {
    return request({
        method: 'GET',
        url: '/api/nsc/front/ndFrontPage/getInfoById/5f29d361-ff1e-4b76-8be5-6cc953bf7a9d',
        params
    })
}

//智慧工具箱详情页接口请求
export const getTygjxxqy = params => {
    return request({
        method: 'GET',
        url: 'https://test-dyh-ywzt-1302104628.cos.ap-nanjing.myqcloud.com/mediadayunhetest/f287f9c9c2bf473ca171ce5505f85710',
        params
    })
}

// 运营赋能接口请求
export const getYyfn = params => {
    return request({
        method: 'GET',
        url: '/api/nsc/front/ndFrontPage/getInfoById/978b7b67-8bf9-448a-87e0-caa5d500f304',
        params
    })
}

//运营赋能详情页接口请求
export const getYyfnxqy = params => {
    return request({
        method: 'GET',
        url: 'https://test-dyh-ywzt-1302104628.cos.ap-nanjing.myqcloud.com/mediadayunhetest/136f030dff124ac6a2a8aaa4d4b4bb70',
        params
    })
}
// export const getYqljj = params => {
//     return request({
//         method: 'GET',
//         url: '/api/nsc/admin/ndFriendlyLink/list',
//         params
//     })
// }

